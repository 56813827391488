import React, { useState, useEffect } from "react";
import './style.scss';
import header from './../../images/header.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserTie,
  faSmile,
  faStream
} from '@fortawesome/free-solid-svg-icons';

export default function HomeContent() {
  return <div className="home-container">
      <div className="header-image">
        <header>
            <div className="header-title" data-aos="fade-in">Skill Hunters Consultancy</div>
            <div className="header-sub-title" data-aos="fade-in">"Opportunities Never Lasts"</div>
        </header>
      </div>

            <div>
            {/* <img src={header} class="img-f" alt="The Skill Hunters"/> */}
            {/* <img src={header} class="img-f"/> */}

            <div className="home-main m-mob5">
                {/* <div className="title">Why Opt for Skill Hunters Consultancy?</div>
                <div className="sub-title">Gauging client Pain Points. Adding Human Touch. Providing tailor-made solutions. Focusing on Quality. Prompt Responses. Close Follow-Ups. Keep the client updated. Working beyond the norms. Respect Commitments.</div>
                <div className="sub-title-2">We accomplish our goal/aim/target by achieving client’s satisfaction.</div> */}
                <div className="row card-items">
                    {/* <div className="card col-md-4 col-sm-12" data-aos="fade-up">
                        <div className="card-title main-title">Why Opt for Skill Hunters Consultancy?</div>
                        <div className="card-subtitle main-sub">Our management emerging successfully from the background of HR & IT professionals, maintain competitive edge over the rivals. Realising the pressure our clients encounter at their workplaces, our agency emphasises on adding human touch to the business solutions with attention to personalisation and care. With our disciplined and focused consultants, we closely understand the business needs and are agile in our daily work schedule and responses; confident in our ability to deliver; and available 24X7 when you need us. We also keep you updated with the prevailing market trend and help you achieve your goals with all possibilities explored.
                        </div>
                    </div> */}
                    <div className="card col-md-3 col-sm-12" data-aos="fade-up">
                        <div className="center"><FontAwesomeIcon className="icon" icon={faUserTie} size="3x" /></div>
                        <div className="card-title">Right Talent Mix</div>
                        <div className="card-subtitle">Our Recruitment Specialist team commit to identify the right talent after thoroughly analysing the technical as well as the soft skills required for a particular job vacancy. With our friendly approach, we encourage the job aspirants to engage in open discussions related to their goals, interests and personal circumstances thus helping them secure the right jobs. With a blend of right talent, we help achieve the organisations their objectives.  
                        </div>
                    </div>
                    <div className="card col-md-3 col-sm-12" data-aos="fade-up">
                        <div className="center"><FontAwesomeIcon className="icon" icon={faStream} size="3x" /></div>
                        <div className="card-title">Niche to Mainstream</div>
                        <div className="card-subtitle">We not only specialise in niche requirements but also cover all mainstream requirements of IT and Non-IT industries including BFSI, Manufacturing, Automobile, Aviation, Construction, Electrical, Electronics etc. Recruitment is a combination of art and science, it doesn’t matter which sector we specialise in, our consultants work smartly understanding the importance of keywords.</div>
                    </div>
                    <div className="card col-md-3 col-sm-12" data-aos="fade-up">
                        <div className="center"><FontAwesomeIcon className="icon" icon={faSmile} size="3x" /></div>
                        <div className="card-title">Deliver Client Satisfaction</div>
                        <div className="card-subtitle">Our expert team reviews the work as per the quality standards and delivers it on time. We stay friendly, helpful, transparent and provide regular updates. We consistently raise the bar by taking feedbacks on critical parameters to provide the best customer experience. We make our clients feel important! 
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
}