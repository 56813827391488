import * as React from "react";
import './style.scss';
import HomeContent from "../components/homeContent";
import AboutContent from "../components/aboutContent";
import Services from "../components/services";
import Layout from "../components/layout";
import Contact from "./contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
// styles


const IndexPage = () => {
  React.useEffect(() => {
    AOS.init();
    console.log('mount it!');
}, []); 
  return (
    <Layout>
      
      <div className="con">
      <div id="home" className="home-page scroll-margin">
        <HomeContent></HomeContent>
      </div>
      <div id="about" className="scroll-margin">
        <AboutContent></AboutContent>
      </div>
      <div id="services" className="scroll-margin">
        <Services></Services>
      </div>
      <div id="contact" className="scroll-margin">
        <Contact></Contact>
      </div>
      </div>
      
    </Layout>
    
  )
}

export default IndexPage
