import * as React from "react";
import './style.scss';
export default function Services() {
  return <div className="services m-mob5">
    <ul></ul>
            <div className="title">
            We Add Value To Services
            </div>
            <div className="sub-title">
            With the array of HR & IT services, we cater to all your needs and expectations!
            </div>
            <div className="card-lists row">
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Recruitment
                    </div>
                    <div className="sub-title">
                    Seeking an exact fit for the role does not just need screening profile but also need analysis of soft skills and the interest of the candidates as well. We manage the process through the interview to offer with close follow-ups; Mediate between candidate and company throughout the interview process; and make the process smooth by providing quick responses.
                    </div>
                </div>
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Payroll Outsourcing
                    </div>
                    <div className="sub-title">
                    Want to outsource the complex payroll system to the team of HR experts? We provide consultation on taxation, Provident Fund & Employee State Insurance and 
                    related legal concerns. With stringent and changing government statutory laws, we ensure pleasant experiences through the annual audits.
                    </div>
                </div>
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Organisation Training & Development
                    </div>
                    <div className="sub-title">
                    Do you know the skill inventory your company has? We help you creating one! Conduct effective technical and impactful soft skill trainings for skill enhancement or project requirement with our available team of professional trainers.
                    </div>
                </div>
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Employment History & Reference Check
                    </div>
                    <div className="sub-title">
                    Receive fast and honest employment history validation and reference checks to make the right decision of hiring. You can build your own list of questions or choose from a wide variety of pre-built templates that follow best practices compliant with worldwide regulations. 
                    </div>
                </div>
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Software Solutions
                    </div>
                    <div className="sub-title">
                    We automate the complex business processes bringing about digital transformation within the organisation that decreases errors, increases speed & quality, minimises costs and simplifies the process.
                    </div>
                </div>
                <div className="card col-md-3" data-aos="fade-up">
                    <div className="title card-title">
                    Website Design & Development
                    </div>
                    <div className="sub-title">
                    Create good first impression on your customers by designing an attractive website. You can also build an online store to start your business. Let your ideas become reality!
                    </div>
                </div>
            </div>
        </div>
}