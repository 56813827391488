import * as React from "react";
import './style.scss';
import about from './../../images/about.jpeg'
export default function AboutContent() {
  return <div className="about-main m-mob5">
          <div className="row">
          {/* <div className="col-md-4">
            <img src={about} className="rounded float-left title-img" alt="..."/>
          </div> */}
              <div className="col-md-12 col-sm-12 content">
                  <div className="title underline">
                  Who We Are
                  </div>
                  {/* <div className="sub-title">
                  Finest Talent Hunting Firm/Organisation/Agency !
                  </div><br></br> */}
                  <div className="sub-title-two">
                  Skill Hunters Consultancy (P) Ltd. is a platform for experiencing the finest solutions for the development of the core assets of the organisations, Human Resource (HR) & Information Technology (IT). Being a reputed and trusted consulting firm, our focus remains on achieving client satisfaction through systematic, flexible and distinctive innovative practices. We are proactive and driven to maximise performance because we believe that your success is our success.
                  </div>
                  <div className="sub-title-two">
                  Skill Hunters Consultancy (P) Ltd. is an excellent Job fair that brings top employers and potential job seekers under one roof. We possess an excellent track record of attracting the best talent from different industries and placing them for the right positions because great companies deserve great employees. Our organisation is well-equipped to provide comprehensive end-to-end HR solutions, including Payroll services, Soft Skill/Technical Trainings and Employee Background Verification, on the basis of our clients’ requirement. 
                  </div>
                  <div className="sub-title-two">
                  Continued technological advancements and cut-throat competition reiterate the importance of digitization in today’s times to perform better. Go paperless in internal processes and we help you do that! We make sure to consistently raise the bar for our clients and pioneer new approaches by offering tailor-made solutions fitting for their organisations.
                  </div><br></br>
                  <div className="sub-title">
                  WE'RE DIFFERENT THAN THE REST 
                  </div>
                  <div className="sub-title-two">
                  Our management emerging successfully from the background of HR & IT professionals, maintain competitive edge over the rivals. Realising the pressure our clients encounter at their workplaces, our agency emphasises on adding human touch to the business solutions with attention to personalisation and care. With our disciplined and focused consultants, we closely understand the business needs and are agile in our daily work schedule and responses; confident in our ability to deliver; and available 24X7 when you need us. We also keep you updated with the prevailing market trend and help you achieve your goals with all possibilities explored.
                  </div>
              </div>
              {/* <div className="col-md-6 col-sm-12 content clear">
                  <div className="row vision">
                    <div className="col-md-2">
                      <div className="v-title">
                        Vision
                      </div>
                      <div className="v-subtitle">
                      What we Think
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="content">
                        Realising the stress at workplaces, we emphasise on adding human touch to our business solutions with attention to personalisation and care. Building trust and connectedness with the clients and workers creating positive bubble/space is what we aim for. Adhering to a set of high standards and code of conduct would help us achieve workplace success. For attaining better productivity, we bring-in/introduce effective hassle-free policies and processes to make tasks easier. 
                        <br></br>
                        “Striving for excellence by adding human touch and following highest quality standards give us satisfaction.”
                        <br></br>
                      </div>
                    </div>
                  </div>
                  <div className="row mission">
                  <div className="col-md-2">
                    <div className="v-title">
                      Vision
                    </div>
                    <div className="v-subtitle">
                    What we Must
                    </div>
                  </div>
                  <div className="col-md-10">
                      <div className="content">
                      We yearn to become the leaders in providing world-class/highest quality exceptional HR solutions to the flourishing businesses especially in the IT sector. 
                        Management with the successful background of HR & IT Professionals, Skill Hunters Consultancy (P) Ltd. maintain competitive edge over the rivals. With our expertise and through our deep market research, we suggest for ideal solutions, thus, growing our customer base.  
                      After gaining immense experience as HR Professionals in different domains, the management have profound understanding of the operations in the IT/Non IT firms that help delivering cutting-edge solutions to our clients.

                      </div>
                    </div>
                  </div>
              </div> */}
              <div className="quotet-items">
                <div class="otro-blockquote">
                IF YOU CAN DREAM IT, YOU CAN DO IT!
                </div>  
              </div>
              <div class="sub-items">
                Our vision is to become the largest and top-ranked consulting firm providing robust and results driven world-class customer services in HR & IT globally. With in-depth market research and profound understanding of the operations, we deliver cutting-edge solutions, thus, growing our customer base. We tend to create a positive space building trust and sustaining long-term relations with all the stakeholders. 
              </div>
              <div className="quotet-items">
                <div class="otro-blockquote">
                DREAM IT. BELIEVE IT. ACHIEVE IT!
                </div>  
              </div>
              <div class="sub-items">
              Our mission is to achieve “Workplace Success” which is critically important to showcase our best performance. We believe in adopting the finest industry practices, adhering to workplace ethics, maintaining professionalism and streamlining processes of highest quality standards for the best outcome. To maintain powerful presence in the market, creating “Good Impression” on our customers is what we always strive for.
              </div>
          </div>
        </div>
}